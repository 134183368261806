
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Hub Balance',
    props: {
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            lastYear: {},
            last30d: {}
        }
    },
    methods: {
        loadAllData() {
            this.loadLastYear()
            this.loadLast30d()
        },
        resetAllData() {
            this.lastYear = {}
            this.last30d = {}
        },
        async loadLastYear() {
            const startDate = (new Date(new Date().getFullYear(), 0, 1)).toISOString().split('T')[0]
            const payload = {
                start_date: startDate
            }

            const uniquekey = 'cache:wma:transactions_overall_lastyear'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.lastYear = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/transactions-overall/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.lastYear = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        async loadLast30d() {
            // load last 30 days then append
            const endDate: any = new Date
            const startDate = (new Date(endDate.getTime() - 30 * 24 * 60 * 60000)).toISOString().split('T')[0]

            const payload = {
                start_date: startDate, 
            }
            
            const uniquekey = 'cache:wma:transactions_overall_last30d'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.last30d = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/transactions-overall/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.last30d = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
    },
    watch: {
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadAllData()
                }
            }
        }
    }
})
