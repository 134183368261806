<template>
    <div class="card">
        <b class="uppercase">Locations</b>
        <hr class="card-divider" />
        <div class="mt-2 flex">
            <div style="width: 30%">
                <div>
                    <div class="bold-text">
                        <text>Total:</text>
                        <text class="big ml-1">{{ locations.length }}</text>
                    </div>
                    <div class="pt-2">
                        <text>Recycling Plant:</text>
                        <text class="big ml-1">{{ plantCount }}</text>
                    </div>
                    <div class="pt-2">
                        <text>Hub Tier 1:</text>
                        <text class="big ml-1">{{ hub1Count }}</text>
                    </div>
                    <div class="pt-2">
                        <text>Hub Tier 2:</text>
                        <text class="big ml-1">{{ hub2Count }}</text>
                    </div>
                </div>
            </div>
            <div style="width: 70%" class="ml-1">
                <div>
                    <b>Locations distribution</b>
                    <img class="map-placeholder mt-2" src="@/../public/assets/map-main-placeholder.jpg"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Locations Overview',
    props: {
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            locations: [] as any[],
        }
    },
    computed: {
        plantCount(): number {
            return this.locations.filter((el: any) => { return el.locationtype.level == 0 }).length
        },
        hub1Count(): number {
            return this.locations.filter((el: any) => { return el.locationtype.level == 1 }).length
        },
        hub2Count(): number {
            return this.locations.filter((el: any) => { return el.locationtype.level == 2 }).length
        },
    },
    methods: {
        resetAllData() {
            this.locations = []
        },
        async loadLocations() {
            const uniquekey = 'cache:wma:locations'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.locations = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/locations'))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.locations = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        }
    },
    watch: {
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadLocations()
                }
            }
        }
    }
})
</script>

<style scoped>
.big {
    font-size: 1.5em;
}
</style>