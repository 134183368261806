<template>
    <div class="card bold-text">
        <b class="uppercase">Transactions Executed</b>
        <hr class="card-divider" />
        <div class="mt-2 bold-text flex">
            <div style="width: 50%">
                <div>
                    <text>Financial Transactions</text>
                    <hr class="card-divider2" />
                    <div class="flex-grid">
                        <div>
                            <text>This Year:</text>
                            <text class="big ml-1">{{ lastYear?.financial_overall }}</text>
                        </div>
                        <div style="margin-left: auto; margin-right: 5%;">
                            <text>Last 30 Days:</text>
                            <text class="big ml-1">{{ last30d?.financial_overall }}</text>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 50%" class="ml-1">
                <div>
                    <text>Physical Transactions</text>
                    <hr class="card-divider2" />
                    <div class="flex-grid">
                        <div>
                            <text>This Year:</text>
                            <text class="big ml-1">{{ lastYear?.physical_overall }}</text>
                        </div>
                        <div style="margin-left: auto; margin-right: 5%;">
                            <text>Last 30 Days:</text>
                            <text class="big ml-1">{{ last30d?.physical_overall }}</text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Hub Balance',
    props: {
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            lastYear: {},
            last30d: {}
        }
    },
    methods: {
        loadAllData() {
            this.loadLastYear()
            this.loadLast30d()
        },
        resetAllData() {
            this.lastYear = {}
            this.last30d = {}
        },
        async loadLastYear() {
            const startDate = (new Date(new Date().getFullYear(), 0, 1)).toISOString().split('T')[0]
            const payload = {
                start_date: startDate
            }

            const uniquekey = 'cache:wma:transactions_overall_lastyear'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.lastYear = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/transactions-overall/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.lastYear = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        async loadLast30d() {
            // load last 30 days then append
            const endDate: any = new Date
            const startDate = (new Date(endDate.getTime() - 30 * 24 * 60 * 60000)).toISOString().split('T')[0]

            const payload = {
                start_date: startDate, 
            }
            
            const uniquekey = 'cache:wma:transactions_overall_last30d'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.last30d = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/transactions-overall/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.last30d = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
    },
    watch: {
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadAllData()
                }
            }
        }
    }
})
</script>

<style scoped>
.big {
    font-size: 1.5em;
}

.flex-grid {
    display: flex
}

@media screen and (max-width: 720px) {
    .flex-grid {
        display: grid
    }
}
</style>