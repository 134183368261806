<template>
    <div class="card">
        <b class="uppercase">Sold Waste - </b><b>Last 30 days</b>
        <hr class="card-divider" />
        <ion-grid>
            <ion-row>
                <ion-col size="3.5">
                    <b>{{ overallSoldWaste }} kg</b>
                    <div class="mt-2">
                        <div class="mt-1" v-for="item of sold_waste" :key=item>
                            <text>
                                {{ getPercentage(item.total_amount) }} % {{ item.waste_type.attribute_data.name}}
                            </text>
                        </div>
                    </div>
                </ion-col>
                <ion-col size="0.5"></ion-col>
                <ion-col size="8">
                    <sold-waste-bar-chart :yvals="sold_waste"></sold-waste-bar-chart>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</template>

<script lang="ts">
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import SoldWasteBarChart from '../charts/SoldWasteBarChart.vue';

export default defineComponent({
    name: 'Sold Waste',
    components: {
        IonGrid, IonRow, IonCol,
        SoldWasteBarChart
    },
    props: {
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            sold_waste: [] as any[]
        }
    },
    methods: {
        async loadSoldWaste() {
            //load last 30 days then append
            const endDate: any = new Date
            const startDate = (new Date(endDate.getTime() - 30 * 24 * 60 * 60000)).toISOString().split('T')[0]
            
            const payload = {
                start_date: startDate
            }

            const uniquekey = 'cache:wma:sold_waste_overall'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.sold_waste = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/sold-waste-overall/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.sold_waste = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        getPercentage(val: any) {
            return (val / this.overallSoldWaste * 100).toFixed(1)
        },
        resetAllData() {
            this.sold_waste = []
        }
    },
    computed: {
        overallSoldWaste(): any {
            let sum = 0.0
            for(const waste_type of this.sold_waste) {
                sum += parseFloat(waste_type.total_amount)
            }
            return sum.toFixed(2)
        }
    },
    watch: {
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadSoldWaste()
                }
            }
        }
    }
})
</script>

<style scoped>
.text-resize1_5v {
    font-size: 1.5vw;
}
</style>