
import { IonPage, IonContent, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { defineComponent } from 'vue';
import TransactionsExecuted from './components/dashboardTemplates/TransactionsExecuted.vue';
import WasteSold from './components/dashboardTemplates/WasteSold.vue';
import UsersSubscription from './components/dashboardTemplates/UsersSubscription.vue';
import YearlyRecycleGoal from './components/dashboardTemplates/YearlyRecycleGoal.vue';
import LocationsOverview from './components/dashboardTemplates/LocationsOverview.vue';

export default defineComponent({
  name: 'Dashboard',
  components: {
    IonPage, IonContent, IonGrid, IonCol, IonRow,
    TransactionsExecuted, WasteSold, UsersSubscription, YearlyRecycleGoal, LocationsOverview
  },
  data() {
    return {
        triggerDestroy: true,
    }
  },
  ionViewDidEnter() {
    this.triggerDestroy = false
  },
  ionViewDidLeave() {
    this.triggerDestroy = true
  }
});
