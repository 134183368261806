<template>
    <div class="app">
        <apexchart
            type="bar"
            :options="chartOptions"
            :series="series"
        ></apexchart>
    </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
    props: { yvals: Array, xvals: Array },
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    id: "vuechart-bar-soldwaste",
                    height: 250,
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: '20%',
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                stroke: {
                    show: true,
                    width: 2
                },
                noData: {
                    text: 'Loading...'
                },
                grid: {
                    show: false,
                },
                xaxis: {
                    type: 'category',
                    labels: {
                        style: {
                            fontSize: '12px',
                            fontWeight: 600
                        }
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    show: false
                },
                tooltip: {
                    enabled: false,
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                        return val + ' ' + w.config.series[seriesIndex].data[dataPointIndex].extra;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                }
            },
            series: [] 
        }; 
    },
    watch: {
        yvals: {
            deep: true,
            handler: function(newval) {
                const structuredData = []
                for( const i of newval) {
                    structuredData.push({x: i.waste_type.attribute_data.name, y: i.total_amount, extra: i.measure_unit.attribute_data})
                }
                console.log(structuredData)
                if (structuredData.length == 0) {
                    this.chartOptions = {...this.chartOptions,
                    ...{
                            xaxis: {
                                labels: {
                                    show: false
                                }
                            },
                            noData: {
                                text: 'No Data Available.'
                            }
                        }
                    }
                }
                this.series = [{name: 'serie', data: structuredData}]
            }
        },
        xvals: {
            deep: true,
            handler: function(newval) {
                this.chartOptions = {...this.chartOptions,
                    ...{
                        xaxis: {
                            categories: newval,
                        }
                    }
                }
            }
        }
    }
}
</script>


<style scoped>

</style>
