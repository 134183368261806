<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row class="mt-2" style="text-align: center;">
            <ion-col class="ion-hide-xl-down" size="1"></ion-col>
            <ion-col size-xl="10" size-sm>
                <b>Dashboard</b>
            </ion-col>
            <ion-col class="ion-hide-xl-down" size="1"></ion-col>
        </ion-row>

        <ion-row class="mt-2">
            <ion-col class="ion-hide-xl-down" size="1"></ion-col>
            <ion-col size-xl="4.5" size-sm="12">
                <yearly-recycle-goal :destroy="triggerDestroy"></yearly-recycle-goal>
            </ion-col>
            <ion-col class="ion-hide-xl-down" size="0.2"></ion-col>
            <ion-col size-xl="5.3" size-sm="12">
                <transactions-executed :destroy="triggerDestroy"></transactions-executed>
            </ion-col>
            <ion-col class="ion-hide-xl-down" size="1"></ion-col>
        </ion-row>

        <ion-row class="mt-2">
            <ion-col class="ion-hide-xl-down" size="1"></ion-col>
            <ion-col size-xl="4.9" size-xs="12">
                <waste-sold :destroy="triggerDestroy"></waste-sold>
            </ion-col>
            <ion-col class="ion-hide-xl-down" size="0.2"></ion-col>
            <ion-col size-xl="4.9" size-xs="12">
                <locations-overview :destroy="triggerDestroy"></locations-overview>
            </ion-col>
            <ion-col class="ion-hide-xl-down" size="1"></ion-col>
        </ion-row>

        <ion-row class="mt-2">
            <ion-col class="ion-hide-xl-down" size="1"></ion-col>
            <ion-col size-xl="10" size-sm="12">
                <users-subscription :destroy="triggerDestroy"></users-subscription>
            </ion-col>
            <ion-col class="ion-hide-xl-down" size="1"></ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { defineComponent } from 'vue';
import TransactionsExecuted from './components/dashboardTemplates/TransactionsExecuted.vue';
import WasteSold from './components/dashboardTemplates/WasteSold.vue';
import UsersSubscription from './components/dashboardTemplates/UsersSubscription.vue';
import YearlyRecycleGoal from './components/dashboardTemplates/YearlyRecycleGoal.vue';
import LocationsOverview from './components/dashboardTemplates/LocationsOverview.vue';

export default defineComponent({
  name: 'Dashboard',
  components: {
    IonPage, IonContent, IonGrid, IonCol, IonRow,
    TransactionsExecuted, WasteSold, UsersSubscription, YearlyRecycleGoal, LocationsOverview
  },
  data() {
    return {
        triggerDestroy: true,
    }
  },
  ionViewDidEnter() {
    this.triggerDestroy = false
  },
  ionViewDidLeave() {
    this.triggerDestroy = true
  }
});
</script>

<style scoped>


</style>