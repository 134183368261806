<template>
    <div class="app">
        <apexchart
            type="line"
            height="300"
            :options="chartOptions"
            :series="series"
        ></apexchart>
    </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
    props: { yvals: Object },
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    id: "vuechart-line-users",
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    type: 'category'
                },
                yaxis: {
                    title: {
                        text: 'N.Subscriptions'
                    },
                    min: 0
                }
            },
            series: []
        }; 
    },
    watch: {
        yvals: {
            deep: true,
            handler: function(newval) {
                const structuredData = []
                for( const value of Object.values(newval)) {
                    structuredData.push({x: value.date, y: value.count})
                }
                console.log(structuredData)
                if (newval.length == 0) {
                    this.chartOptions = {...this.chartOptions,
                    ...{
                            xaxis: {
                                labels: {
                                    show: false
                                }
                            },
                            noData: {
                                text: 'No Data Available.'
                            }
                        }
                    }
                }
                
                this.series = [{name: 'Subscriptions', data: structuredData}]
            }
        }
    }
}
</script>


<style scoped>

</style>
