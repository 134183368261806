
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Locations Overview',
    props: {
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            locations: [] as any[],
        }
    },
    computed: {
        plantCount(): number {
            return this.locations.filter((el: any) => { return el.locationtype.level == 0 }).length
        },
        hub1Count(): number {
            return this.locations.filter((el: any) => { return el.locationtype.level == 1 }).length
        },
        hub2Count(): number {
            return this.locations.filter((el: any) => { return el.locationtype.level == 2 }).length
        },
    },
    methods: {
        resetAllData() {
            this.locations = []
        },
        async loadLocations() {
            const uniquekey = 'cache:wma:locations'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.locations = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/locations'))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.locations = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        }
    },
    watch: {
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadLocations()
                }
            }
        }
    }
})
