
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Yearly Recycle Goal',
    props: {
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            to_date: null as any,
            unit: '',
            goal: null as any,
            percentage: null as any
        }
    },
    methods: {
        resetAllData() {
            this.to_date = null
            this.unit = ''
            this.goal = null
            this.percentage = null
        },
        async loadStats() {
            const startDate = (new Date(new Date().getFullYear(), 0, 1)).toISOString().split('T')[0]
            const payload = {
                start_date: startDate
            }

            const uniquekey = 'cache:wma:recycle_overall'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.goal = parsed?.goal || 0
                this.to_date = parsed.amounts[0]?.total_amount || 0
                this.unit = parsed.amounts[0]?.measure_unit || ''
                if (this.goal && this.goal > 0) {
                    const percValue = ((this.to_date || 0) * 100) / this.goal
                    this.percentage = Math.min(percValue, 100)
                }
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/recycle-stat/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.goal = resp.data?.goal || 0
                    this.to_date = resp.data.amounts[0]?.total_amount || 0
                    this.unit = resp.data.amounts[0]?.measure_unit || ''
                    if (this.goal && this.goal > 0) {
                        const percValue = ((this.to_date || 0) * 100) / this.goal
                        this.percentage = Math.min(percValue, 100)
                    }
                } catch (err) { 
                    console.log(err)
                }
            }
        }
    },
    watch: {
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadStats()
                }
            }
        }
    }
})
