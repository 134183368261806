<template>
    <div class="card bold-text">
        <b class="uppercase">Yearly Recycle Goal</b>
        <hr class="card-divider" />
        <div class="ml-1 mt-2 bold-text">
            <div class="flex">
                <div>
                    <text>To Date:</text>
                    <text class="big ml-1">{{ to_date }} {{ unit }}</text>
                </div>
                <div style="margin-left: auto; margin-right: 5%;">
                    <text>Year Goal:</text>
                    <text class="big ml-1">{{ goal }} {{ unit }}</text>
                </div>
            </div>
        </div>
        <div class="width-const ml-1 mt-2 bold-text">
            <div class="flex">
                <text class="actual-color" :style="{'padding-left': percentage*0.5 + '%'}">Current</text>
                <!-- <div class="goal-color">Year Goal</div> -->
            </div>
            <div class="flex">
                <div class="bar actual-bcolor" :style="{'width': percentage + '%'}"></div>
                <div class="width-const bar goal-bcolor"></div>
            </div>
            <div>
                <hr style="border-top: 1px solid black;" />
                <div class="flex">
                    <div>0</div>
                    <div style="margin-left: auto">{{ goal }} {{ unit }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';

export default defineComponent({
    name: 'Yearly Recycle Goal',
    props: {
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            to_date: null as any,
            unit: '',
            goal: null as any,
            percentage: null as any
        }
    },
    methods: {
        resetAllData() {
            this.to_date = null
            this.unit = ''
            this.goal = null
            this.percentage = null
        },
        async loadStats() {
            const startDate = (new Date(new Date().getFullYear(), 0, 1)).toISOString().split('T')[0]
            const payload = {
                start_date: startDate
            }

            const uniquekey = 'cache:wma:recycle_overall'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.goal = parsed?.goal || 0
                this.to_date = parsed.amounts[0]?.total_amount || 0
                this.unit = parsed.amounts[0]?.measure_unit || ''
                if (this.goal && this.goal > 0) {
                    const percValue = ((this.to_date || 0) * 100) / this.goal
                    this.percentage = Math.min(percValue, 100)
                }
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.post(''.concat(config['host_ip'], config['host_port'], '/recycle-stat/'), payload)
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.goal = resp.data?.goal || 0
                    this.to_date = resp.data.amounts[0]?.total_amount || 0
                    this.unit = resp.data.amounts[0]?.measure_unit || ''
                    if (this.goal && this.goal > 0) {
                        const percValue = ((this.to_date || 0) * 100) / this.goal
                        this.percentage = Math.min(percValue, 100)
                    }
                } catch (err) { 
                    console.log(err)
                }
            }
        }
    },
    watch: {
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadStats()
                }
            }
        }
    }
})
</script>

<style scoped>
.big {
    font-size: 1.5em;
}

.width-const {
    width: 84vw;
}

@media screen and (min-width: 1200px) {
    .width-const {
        width: 33vw;
    }
}

@media screen and (min-width: 576px) {
}

.bar {
    height: 45px;
}

.actual-color {
    color: #4999f4;
}

.goal-color {
    color: #92ccfd;
    position: absolute;
    right: 54px;
}

.actual-bcolor {
    background: #4999f4;
    z-index: 1;
}

.goal-bcolor {
    background: #92ccfd;
    position: absolute;
}
</style>