
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue';
import axios from 'axios';
import config from '@/../public/assets/conf.json';
import UsersLineChart from '../charts/UsersLineChart.vue';

export default defineComponent({
    name: 'Sold Waste',
    components: {
        UsersLineChart
    },
    props: {
        destroy: { type: Boolean, default: false }
    },
    data() {
        return {
            users: [] as any[]
        }
    },
    methods: {
        async loadUsersOverall() {
            const uniquekey = 'cache:wma:users_overall'
            const cached = localStorage.getItem(uniquekey)
            if (cached) {
                const parsed = JSON.parse(cached)
                this.users = parsed
            }

            if (navigator.onLine) {
                try {
                    const resp = await axios.get(''.concat(config['host_ip'], config['host_port'], '/users-overall/'))
                    localStorage.setItem(uniquekey, JSON.stringify(resp.data))
                    this.users = resp.data
                } catch (err) { 
                    console.log(err)
                }
            }
        },
        resetAllData() {
            this.users = []
        }
    },
    watch: {
        destroy: {
            handler: function(newVal) {
                if (newVal) {
                    this.resetAllData()
                } else {
                    this.loadUsersOverall()
                }
            }
        }
    }
})
